@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary: white;
    --secondary: black;
    --selection: var(--cyan);

    --text-base: black;
    --text-primary: black;
    --text-secondary: white;

    --hover: rgba(0, 0, 0, 0.075);
    --hover-1: rgba(0, 0, 0, 0.15);
    --hover-2: rgba(0, 0, 0, 0.25);

    --accent-0: #fff;
    --accent-1: #fafafa;
    --accent-2: #eaeaea;
    --accent-3: #999999;
    --accent-4: #888888;
    --accent-5: #666666;
    --accent-6: #444444;
    --accent-7: #333333;
    --accent-8: #111111;
    --accent-9: #000;
  }

  [data-theme="dark"] {
    --primary: black;
    --secondary: white;
    --selection: var(--purple);

    --text-base: white;
    --text-primary: white;
    --text-secondary: black;

    --accent-9: #fff;
    --accent-8: #fafafa;
    --accent-7: #eaeaea;
    --accent-6: #999999;
    --accent-5: #888888;
    --accent-4: #666666;
    --accent-3: #444444;
    --accent-2: #333333;
    --accent-1: #111111;
    --accent-0: #000;
  }
}

@layer components {
  /* when content is smaller than 100vh, stretch it in a way to make room for header (64px) and footer (4 rem) */
  .content-min-height {
    min-height: calc(100vh - 64px - 88px);
  }

  .highcharts-container,
  .highcharts-container svg {
    width: 100% !important;
  }
}
